<script>
import STable from '@/components/Table/'
import { exportDataToExcel, loadPermissionList, loadTestDataList } from '@/api/data'
import ShowBigImage from '@/views/data/modal/ShowBigImage.vue'
import moment from 'moment'
import * as JsStore from 'jsstore'
import workerInjector from 'jsstore/dist/worker_injector'
import Vue from 'vue'
import VideoPreview from '@/views/data/modal/VideoPreview.vue'

export default {
  name: 'TestRecord',
  components: {
    STable
  },
  data () {
    return {
      inited: false,
      locale: {
        emptyText: this.$t('table.emptyText')
      },
      scrollHeight: document.body.clientHeight - 306,
      col: {
        md: 10,
        lg: 8,
        xl: 6,
        xxl: 4
      },
      qTime: [],
      // 查询参数
      queryParam: {
        name: '',
        time: []
      },
      // 表头
      columns: [
        {
          title: this.$t('user.test.id'),
          dataIndex: '_id',
          align: 'center',
          scopedSlots: { customRender: '_id' }
        },
        {
          title: this.$t('user.test.user'),
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'center',
          scopedSlots: { customRender: 'id' }
        },
        {
          title: this.$t('user.test.date'),
          dataIndex: 'create_date',
          align: 'center',
          scopedSlots: { customRender: 'create_date' }
        },
        {
          title: this.$t('user.test.time'),
          dataIndex: 'create_time',
          align: 'center',
          scopedSlots: { customRender: 'create_time' }
        },
        {
          title: this.$t('user.test.value'),
          dataIndex: 'measure_value',
          align: 'center',
          scopedSlots: { customRender: 'measure_value' }
        },
        {
          title: this.$t('user.test.appraise'),
          dataIndex: 'appraise',
          align: 'center',
          scopedSlots: { customRender: 'appraise' }
        },
        {
          title: this.$t('user.test.image'),
          dataIndex: 'image',
          align: 'center',
          scopedSlots: { customRender: 'image' }
        },
        {
          title: this.$t('user.test.video'),
          dataIndex: 'video',
          align: 'center',
          scopedSlots: { customRender: 'video' }
        },
        {
          title: this.$t('user.test.location'),
          dataIndex: 'location',
          align: 'center',
          scopedSlots: { customRender: 'location' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: async (parameter) => {
       this.newDataNum = 0
        const mId = Vue.ls.get('USRRID')
       this.newDataTag = false
        const where = {
         m_id: mId
        }
        if (this.queryParam._id && this.queryParam._id.length > 0) {
          where._id = parseInt(this.queryParam._id)
        }
        if (this.queryParam.name && this.queryParam.name.length > 0) {
          where.name = {
            like: `%${this.queryParam.name}%`
          }
        }
        if (this.qTime.length > 0) {
          where.create_time = {
            '>=': this.qTime[0].toDate(),
            '<=': this.qTime[1].toDate()
          }
        }

        const totalCount = await this.connection.count({
          from: 'test_record',
          where: Object.keys(where).length > 0 ? where : null
        })
        const dataArr = await this.connection.select({
          from: 'test_record',
          where: Object.keys(where).length > 0 ? where : null,
          order: {
            by: 'create_time',
            type: 'desc'
          },
          limit: parameter.pageSize,
          skip: (parameter.pageNo - 1) * parameter.pageSize
        })
        if (dataArr.length > 0) {
          if (this.lastId) {
            if (dataArr[0]._id > this.lastId) {
              this.lastId = dataArr[0]._id
            }
          } else {
            this.lastId = dataArr[0]._id
          }
        }
        for (const item of dataArr) {
          if (!this.inited) {
            item.disabled = true
          }
          item.create_time = moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')
          item.disabled = this.userLevelMap ? this.userLevelMap[item.user_id] === 0 : true
        }
        return {
          data: dataArr,
          pageSize: parseInt(parameter.pageSize),
          pageNo: parseInt(parameter.pageNo),
          totalPage: Math.ceil(totalCount / parseInt(parameter.pageSize)),
          totalCount: totalCount
        }
      },
      connection: null,
      lastId: null,
      dataLoadTimer: null,
      newDataTag: false,
      users: null,
      selectedRowKeys: [],
      selectedRows: [],
      userInfoMap: null,
      userLevelMap: null,
      imageBase64Map: {}
    }
  },
  created () {
    const _this = this
    this.initData()
    this.$on('logout', () => {
        console.log('logout')
    })
    window.imageLoaded = function (target) {
      (function (t) {
        var canvas = document.createElement('canvas') // 创建canvas DOM元素
        var ctx = canvas.getContext('2d')
        var img = new Image()
        img.crossOrigin = 'Anonymous'
        img.src = t.src
        img.onload = function () {
          canvas.height = t.height // 指定画板的高度,自定义
          canvas.width = t.width // 指定画板的宽度，自定义
          ctx.drawImage(img, 0, 0, t.width, t.height) // 参数可自定义
          var dataURL = canvas.toDataURL('image/jpeg')
          _this.imageBase64Map[t.dataset.id] = {
            width: t.width,
            height: t.height,
            dataURL: dataURL
          }
        }
      })(target)
    }
  },
  beforeDestroy () {
    if (this.dataLoadTimer) {
      clearTimeout(this.dataLoadTimer)
      this.dataLoadTimer = null
    }
    if (this.connection) {
      this.connection.terminate()
    }

    window.imageLoaded = null
  },
  methods: {
    async initData () {
      const _this = this
      const mId = Vue.ls.get('USRRID')
      let hide = null
      const key = 'downloading'
      if (!this.inited) {
        hide = this.$message.loading({ content: 'データの同期中、進行状況：0%。', key, duration: 0 })
      }
      var connection = null
      // https://jsstore.net/
      if (!this.connection) {
        connection = new JsStore.Connection()
        connection.addPlugin(workerInjector)
        this.connection = connection
      } else {
        connection = this.connection
      }

      await connection.initDb({
        name: 'record',
        tables: [{
          name: 'test_record',
          columns: {
            _id: { primaryKey: true },
            id: { dataType: 'string' },
            m_id: { notNull: false, dataType: 'number' },
            user_id: { notNull: true, dataType: 'number' },
            name: { dataType: 'string' },
            device_name: { notNull: false, dataType: 'string' },
            mac: { notNull: false, dataType: 'string' },
            measure_value: { notNull: true, dataType: 'number' },
            image_path: { dataType: 'string' },
            location: { dataType: 'object' },
            create_time: { notNull: true, dataType: 'date_time' },
            video: { dataType: 'string' }
          },
          alter: {
            // for version 2
            2: {
              modify: {
                device_name: {
                  notNull: false
                }
              }
            },
            3: {
              modify: {
                mac: {
                  notNull: false
                }
              }
            },
            4: {
              add: {
                video: {
                  notNull: false,
                  dataType: 'string'
                }
              }
            },
            5: {
              add: {
                m_id: {
                  notNull: false,
                  dataType: 'number'
                }
              }
            }
          }
        }],
        version: 5
      })
      await this.sleep(1000)
      const idNewUser = Vue.ls.get('NEWU')
      if (idNewUser) {
        await connection.clear('test_record')
        Vue.ls.remove('NEWU')
      }
      const pres = await loadPermissionList({
        type: 'all',
        pageNo: 1,
        pageSize: 1000
      })
      const users = pres.result.data
      this.users = users
      const userInfoMap = {}
      const userLevelMap = {}
      let index = 0
      for (const user of users) {
        userLevelMap[user.user_id] = user.user_level
        index++

        userInfoMap[user.user_id] = user.user

        if (user.delete_tag === 1 || user.status === 0) {
          await connection.remove({
            from: 'test_record',
            where: {
              user_id: user.user_id
            }
          })
          this.refreshTable()
        } else {
          if (user.user_level === 0) {
            await connection.remove({
              from: 'test_record',
              where: {
                user_id: user.user_id,
                create_time: {
                  '<': moment().subtract(2, 'days').toDate()
                }
              }
            })
          }

          const recordResp = await loadTestDataList({ pageSize: this.inited ? 10 : 200, pageNo: 1, user_id: user.user_id })
          const records = recordResp.result.data
          const values = []
          for (const record of records) {
            if ((!this.newDataTag) && (this.lastId) && record.record_id > this.lastId) {
              this.newDataTag = true
            }
            values.push({
              _id: record.record_id,
              user_id: user.user_id,
              m_id: mId,
              id: record.id,
              name: record.name,
              device_name: record.device_name,
              mac: record.mac,
              measure_value: record.measure_value,
              image_path: record.image_path,
              location: record.location,
              video: record.video,
              create_time: new Date(record.create_time_stamp)
            })
          }

          await connection.insert({
            into: 'test_record',
            upsert: true,
            values: values
          })
        }

        if (!this.inited) {
          hide = this.$message.loading({
            content: `データの同期中、進行状況：${(index * 100 / users.length).toFixed(2)}%。`,
            key,
            duration: 0
          })
        }
      }
      if (!this.inited) {
        hide = this.$message.success({
          content: `データの同期が完了しました。`,
          key
        })
        setTimeout(hide, 2000)
        this.userInfoMap = userInfoMap

        this.inited = true
        this.refreshTable()
      }
      this.userLevelMap = userLevelMap
      this.dataLoadTimer = setTimeout(function () {
        _this.initData()
      }, 5000)
    },
    disabledDate (current) {
      // Can not select days before today and today
      // return current && (current > moment().endOf('day') || (current < moment().subtract(2, 'days').startOf('day')))
      return current && (current > moment().endOf('day'))
    },
    formatValue: (value, disabled) => {
      console.log(value, disabled)
      if (disabled) {
        return '--'
      }
      let v = (value * 5).toFixed(2)
      if (value < 0.015) {
        v = '0.00'
      }
      return v
    },
    refreshTable () {
      if (this.qTime.length > 0) {
        this.queryParam.time = this.qTime[0].format('YYYY-MM-DD 00:00:00') + ',' + this.qTime[1].format('YYYY-MM-DD 23:59:59')
      }
      this.$refs.table.refresh()
    },
    showImage (url) {
      this.showModal(ShowBigImage, this.$t('user.test.image'), 380, 460, { url: 'https://www.breathmeters.net' + url })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    getCheckboxProps (record) {
      return {
        props: {
          disabled: record.disabled
        }
      }
    },
    sleep (t) {
      return new Promise(resolve => setTimeout(resolve, t))
    },
    playVideo (video) {
      this.showModal(VideoPreview, this.$t('user.test.video'), 380, 480, { name: video })
    },
    async exportData () {
      const hide = this.$message.loading(this.$t('user.excel.exporting'), 0)
      const randomId = ('0000000' + Math.floor(Math.random() * 100000)).slice(-7)
      var date = moment().format('YYYY-MM-DD')
      var tmpFileName = `Alcohol_Check_${randomId}_${date}.xlsx`
      const data = []
      for (let i = 0; i < this.selectedRows.length; i++) {
        const item = this.selectedRows[i]
        const obj = {}
        const colorMap = {
          white_green: '白地に緑文字',
          green_white: '緑地に白文字',
          yellow_black: '黄色地に黒文字',
          black_yellow: '黒地に黄色文字',
          blue_green: '青地に緑文字',
          white_red: '白地に赤い斜線'
        }
        if (this.userInfoMap[item.user_id] && this.userInfoMap[item.user_id].color && this.userInfoMap[item.user_id].color.length > 0) {
          obj.color = colorMap[this.userInfoMap[item.user_id].color]
        } else {
          obj.color = '-'
        }
        obj.id = this.userInfoMap[item.user_id] ? (this.userInfoMap[item.user_id].uId || item.id) : item.id
        obj.name = this.userInfoMap[item.user_id] ? (this.userInfoMap[item.user_id].name || item.name) : item.name
        obj.area = this.userInfoMap[item.user_id] ? (this.userInfoMap[item.user_id].area || '-') : '-'
        obj.plateName = this.userInfoMap[item.user_id] ? (this.userInfoMap[item.user_id].plateName || '-') : '-'
        obj.number = this.userInfoMap[item.user_id] ? (this.userInfoMap[item.user_id].number || '-') : '-'
        obj.type = this.userInfoMap[item.user_id] ? (this.userInfoMap[item.user_id].type || '-') : '-'
        obj.image = this.imageBase64Map[item._id]
        obj.date = item.create_time.slice(0, 10)
        obj.time = item.create_time.slice(11)
        obj.value = this.formatValue(item.measure_value)
        obj.appraise = item.measure_value > 0.03 ? this.$t('user.excel.appraise.no') : this.$t('user.excel.appraise.yes')
        data.push(obj)
      }
      const resp = await exportDataToExcel({
        fileName: `alc_test_data.xlsx`,
        sheets: [{
          title: 'test_record',
          columns: [{
            title: this.$t('user.id'),
            dataIndex: 'id',
            width: 11
          },
            {
              title: this.$t('user.name'),
              dataIndex: 'name',
              width: 22
            },
            {
              title: this.$t('user.car.area'),
              dataIndex: 'area',
              width: 10
            },
            {
              title: this.$t('user.car.type'),
              dataIndex: 'type',
              width: 12
            },
            {
              title: this.$t('user.car.plateName'),
              dataIndex: 'plateName',
              width: 10
            },
            {
              title: this.$t('user.car.number'),
              dataIndex: 'number',
              width: 15
            },
            {
              title: this.$t('user.car.color'),
              dataIndex: 'color',
              width: 19
            },
            {
              title: this.$t('user.test.date'),
              dataIndex: 'date',
              width: 15
            },
            {
              title: this.$t('user.test.time'),
              dataIndex: 'time',
              width: 13
            },
            {
              title: this.$t('user.test.value'),
              dataIndex: 'value',
              width: 18
            },
            {
              title: this.$t('user.test.appraise'),
              dataIndex: 'appraise',
              width: 18
            },
            {
              title: this.$t('user.test.image'),
              dataIndex: 'image',
              type: 'image',
              width: 24
            }],
          data: data
        }]
      })
      this.saveResultAsExcel(resp, tmpFileName)
      hide()
    }
  }
}
</script>

<template>
  <div>
    <div>
      <div class="ant-card" style="padding: 8px;">
        <a-row :gutter="12">
          <a-col :lg="col.lg" :xxl="col.xxl" :xl="col.xl" :md="col.md">
            <div class="form-item">
              <span class="form-item-label">{{ $t('user.test.id') }}:</span>
              <a-input class="form-input" v-model="queryParam._id" allowClear>
              </a-input>
            </div>
          </a-col>
          <a-col :lg="col.lg" :xxl="col.xxl" :xl="col.xl" :md="col.md">
            <div class="form-item">
              <span class="form-item-label">{{ $t('user.test.user') }}:</span>
              <a-input class="form-input" v-model="queryParam.name" allowClear>
              </a-input>
            </div>
          </a-col>
          <a-col :lg="col.lg" :xxl="col.xxl" :xl="col.xl" :md="col.md">
            <div class="form-item">
              <span class="form-item-label">{{ $t('user.test.date') }}:</span>
              <a-range-picker
                class="form-input"
                v-model="qTime"
                allowClear
                :disabled-date="disabledDate"></a-range-picker>
            </div>
          </a-col>
          <a-col :lg="col.lg" :xxl="col.xxl" :xl="col.xl" :md="col.md">
            <div class="form-item">
              <a-button icon="search" @click="refreshTable" type="primary">{{ $t('user.action.search') }}</a-button>
              <a-button icon="export" class="margin-left-16" :disabled="selectedRowKeys.length==0" @click="exportData">
                {{ $t('user.action.download') }}
              </a-button>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="ant-card" style="margin-top: 8px;padding: 8px;">
      <div style="margin-bottom: 8px;">
        <a-alert v-if="newDataTag" type="info" :message="$t('user.test.data.new.tip')"></a-alert>
      </div>
      <div>
        <s-table
          :columns="columns"
          :data="loadData"
          ref="table"
          bordered
          :locale="locale"
          size="small"
          :showPagination="true"
          :rowKey="(record) => record._id"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,getCheckboxProps:getCheckboxProps }"
          :scroll="{x:1220,y:scrollHeight}">
          <span slot="_id" slot-scope="text, record">{{ ("000000000000"+record._id).slice(-12) }}</span>
          <span slot="id" slot-scope="text, record">{{ record.id || '--' }}</span>
          <span slot="license_plate_number" slot-scope="text, record">{{ record.license_plate_number || '--' }}</span>
          <span slot="create_date" slot-scope="text, record">{{ record.create_time.slice(0, 10) }}</span>
          <span slot="create_time" slot-scope="text, record">{{ record.create_time.slice(11) }}</span>
          <span slot="measure_value" slot-scope="text, record" :title="record.disabled?$t('user.test.value.limited'):''">{{ formatValue(record.measure_value,record.disabled) }}</span>
          <span slot="appraise" slot-scope="text, record">
            <a-tag v-if="record.measure_value<0.015" color="green">{{ $t('user.test.appraise.yes') }}</a-tag>
            <a-tag v-else-if="record.measure_value<0.03" color="orange">{{ $t('user.test.appraise.warn') }}</a-tag>
            <a-tag v-else color="red">{{ $t('user.test.appraise.no') }}</a-tag>
          </span>
          <div slot="image" slot-scope="text, record">
            <img
              :data-id="record._id"
              :src="'https://www.breathmeters.net'+record.image_path"
              onload="imageLoaded(this)"
              @click="showImage(record.image_path)"
              style="max-height: 80px;cursor: pointer;"
              v-if="record.image_path" />
            <div v-else>--</div>
          </div>
          <div slot="video" slot-scope="text, record">
            <i class="iconfont icon-bofang" style="font-size: 2rem;cursor: pointer" :title="$t('user.test.video.play')" @click="playVideo(record.video)" v-if="record.video"></i>
            <span v-else></span>
          </div>
          <div slot="location" slot-scope="text, record">
            <div v-if="record.location">
              <a
                :href="'https://www.google.com/maps/place/' + record.location.location"
                target="_blank">{{ record.location.city_name }}</a>
            </div>
            <div v-else>--</div>
          </div>
        </s-table>
      </div>
    </div>
  </div>
</template>

<style scoped lang='less'>
.form-item {
  display: flex;

  .form-item-label {
    width: 60px;
    text-align: end;
    margin-right: 8px;
  }

  .form-input {
    flex: 1;
  }
}

</style>
